/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordReducer } from "../../store/userReducer";

import {
  Row,
  Col,
  Card,
  Modal,
  Button,
  Form,
  Input,
  List,
  Descriptions,
  Avatar,
  Radio,
  Switch,
  Upload,
  message,
} from "antd";

import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";

const Profile = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch("");

  const { crdentialDataForOne } = useSelector((state) => state.login);
  const [openModal, setOpenModal] = useState(false);
  const [previosPassword, setPreviosPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showLabel, setShowLabel] = useState(false);
  const displayModal = (record, handleModal) => {
    setOpenModal(true);
  };
  const handlePreviousPassword = (event) => {
    setPreviosPassword(event.target.value);
  };
  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
  };
  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };
  //
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (newPassword === confirmPassword) {
      dispatch(
        changePasswordReducer({
          oldPassword: previosPassword,
          password: newPassword,
        })
      );
      setIsModalVisible(false);
      setConfirmPassword("");
      setNewPassword("");
      setPreviosPassword("");
    } else {
      setShowLabel(true);
    }
    setNewPassword("");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  //
  return (
    <>
      <div>
        <Modal
          title="Change Password"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form layout="vertical">
            <Form.Item label="Previous Password">
              <Input.Password
                onChange={handlePreviousPassword}
                style={{ height: 40 }}
              ></Input.Password>
            </Form.Item>
            <Form.Item label="New Password">
              <Input.Password onChange={handleNewPassword}></Input.Password>
            </Form.Item>
            <Form.Item label="Confirm Password">
              <Input.Password onChange={handleConfirmPassword}></Input.Password>
              {showLabel && (
                <label style={{ color: "red" }}>
                  New password and confirm password must be the same!
                </label>
              )}
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <Row gutter={[24, 0]}>
        <Col span={24} md={8} className="mb-24">
          <Card
            bordered={false}
            title={
              <h6 className="font-semibold m-0" style={{ color: "#1E90FF" }}>
                Profile Information
              </h6>
            }
            className="header-solid h-full card-profile-information"
            bodyStyle={{ paddingTop: 0, paddingBottom: 6, width: 700 }}
            style={{ width: "300%", height: "150%" }}
          >
            <div className="avatar-info">
              <h4 className="font-semibold m-0">
                {crdentialDataForOne?.first_name}
              </h4>
              <p>
                {crdentialDataForOne?.role}{" "}
                {crdentialDataForOne?.role == "admin"
                  ? ""
                  : "/" + crdentialDataForOne?.warehouse}
              </p>
            </div>
            <hr className="my-25" />
            <Descriptions>
              <Descriptions.Item label="Full Name" span={3}>
                {crdentialDataForOne?.first_name +
                  " " +
                  crdentialDataForOne?.last_name}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile" span={3}>
                (+251) {crdentialDataForOne?.phone}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
                {crdentialDataForOne?.email}
              </Descriptions.Item>
              {crdentialDataForOne?.role == "admin" ? (
                ""
              ) : (
                <Descriptions.Item label="Location" span={3}>
                  {crdentialDataForOne?.warehouse}
                </Descriptions.Item>
              )}
            </Descriptions>
            <Button
              type="primary"
              //style={{ background: "#1E90FF" }}
              onClick={showModal}
            >
              Change Password
            </Button>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
