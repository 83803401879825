import React, { useEffect, useState } from "react";
import {
  Button,
  Space,
  Col,
  Row,
  Form,
  Input,
  Select,
  Upload,
  Modal,
} from "antd";

import { useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductReducer,
  updateProductReducer,
} from "../../store/productReducer";
import {
  getCategoryReducerForActive,
  getCategoryReducerForOne,
} from "../../store/categoryReducers";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { toast } from "react-toastify";
import { toBeVisible } from "@testing-library/jest-dom/dist/matchers";

const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
const App = () => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const [fields, setFields] = useState([]);
  const [qauntityFields, setQauntityFields] = useState([]);

  const [quantityFieldsOnUpdate, setQuantityFieldsOnUpdate] = useState([]);

  const { categories } = useSelector((state) => state.category);
  const { categoriess } = useSelector((state) => state.category);

  const { product } = useSelector((state) => state.product);

  const { id } = useParams();

  const [productData, setProductData] = useState();
  const [editProductData, setEditedProducData] = useState([]);

  const [category, setCategory] = useState("");
  const [qauntity, setQauntity] = useState("");
  const [name, setName] = useState("");
  const [itemCode, setItemCode] = useState("");

  const [categoryVisibility, setCategoryVisibility] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  //
  useEffect(() => {
    dispatch(getCategoryReducerForActive());

    if (id) {
      console.log("Product", product);
      setName(product?.name);
      setCategory(product?.category?.id);
      setQauntity(product?.quantity);
      setItemCode(product?.code);
      setFields(product?.specifications);

      console.log("the selcted category is", categories);

      const dg = JSON.parse(
        JSON.stringify(product?.specifications.map((item) => item.value))
      );
      setEditedProducData(dg);
      setCategoryVisibility(true);

      const selectedCategory = product?.category._id;
      const categoryyy = categories.find(
        (item) => item._id == selectedCategory
      );

      console.log("Category iD", selectedCategory);
      dispatch(getCategoryReducerForOne(selectedCategory));
      const quantityMeasurments = categoriess?.quantity_measurement;
      setQuantityFieldsOnUpdate(product?.category?.quantity_measurement);

      // setQuantityFieldsOnUpdate(
      //   (quantityMeasurments) =>
      //     (quantityMeasurments = categoriess?.quantity_measurement)
      // );
      console.log("here it is", categoriess?.quantity_measurement);
      console.log("The new categories", categoriess);
      console.log("QuantityFields", quantityFieldsOnUpdate);
      ////

      // const selectedCategoryOnupdate = categories.find(
      //   (item) => item._id == selectedCategory
      // );
      // console.log("CAtegoryyy", selectedCategory);

      // setTimeout(() => {
      //   setQuantityFieldsOnUpdate(categoriess?.quantity_measurement);
      //   console.log("SelecetdCategoryyy", categoriess);
      // }, 200);
    }
  }, []);

  useEffect(() => {
    //console.log("eff Spec", editProductData);
  }, [editProductData]);

  const handleProductSubmit = async () => {
    const arr = Object.entries(productData).map(([key, value]) => ({
      name: key,
      value,
    }));
    dispatch(
      addProductReducer({
        name,
        category,
        code: itemCode,
        quantity: qauntity,
        specifications: arr,
      })
    );
    setName("");
    setQauntity([]);
    setCategory([]);
    setItemCode("");
    setFields([]);
  };

  const updateProductOnclick = async () => {
    const specifications = fields.map((item, index) => ({
      ...item,
      value: editProductData[index],
    }));

    dispatch(
      updateProductReducer({
        id,
        name,
        category,
        specifications,
        code: itemCode,
        quantity: qauntity,
      })
    );
    setName("");
    setQauntity([]);
    setCategory([]);
    setItemCode("");
  };

  const handleItemCode = (event) => {
    setItemCode(event.target.value);
  };
  const handleName = (event) => {
    setName(event.target.value);
  };

  const onCategoryChange = (value) => {
    setFields([]);
    const selectedCategory = categories.find((item) => item._id == value);
    setFields(selectedCategory.specifications);
    setQauntityFields(selectedCategory.quantity_measurement);
    setCategory(value);
    setQauntity([]);
    setName("");
    setItemCode("");
  };
  const oncategoryChangeOnExcel = (event) => {
    setCategory(event);
  };

  const handleQauntity = (value) => {
    setQauntity(value);
  };
  useEffect(() => {
    console.log("Quantity", qauntity);
  }, [qauntity]);
  const onReset = () => {
    setName("");
    setCategory("");
    setItemCode("");
    setQauntity("");
    form.resetFields();
  };
  const handleProductData = (event) => {
    event.persist();
    setProductData({
      ...productData,
      [event.target.name]: event.target.value,
    });
  };

  const handleEditedProductData = (index, value) => {
    setEditedProducData((prevoiusProductSpecification) => {
      const newProductSpecification = [...prevoiusProductSpecification];
      newProductSpecification[index] = value;
      return newProductSpecification;
    });
  };

  const handleUpload = () => {
    const formData = new FormData();

    formData.append("file", selectedFile);
    formData.append("category", category);
    axios
      .post(
        "https://erp.marnamanufacturing.com/api/products/import",
        // "http://localhost:5000/api/products/import",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
        // Handle any errors
      });
    setOpenModal(false);
  };
  const handleFileChange = (event) => {
    setSelectedFile(event?.fileList[0]?.originFileObj);
  };
  //
  return (
    <div className="ml-4">
      <Modal
        centered
        onCancel={() => setOpenModal(false)}
        onOk={handleUpload}
        title="Upload Products from Excel"
        visible={openModal}
      >
        <Form layout="vertical">
          <Form.Item label="Product category">
            <Select value={category} onChange={oncategoryChangeOnExcel}>
              {categories?.map((item, key) => (
                <option key={key} value={item._id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item style={{ textAlign: "left" }}>
            <Upload onChange={handleFileChange}>
              <Button
                icon={<UploadOutlined />}
                style={{
                  backgroundColor: "#a5b4fc",
                  color: "white",
                  width: "150px",
                  textAlign: "left",
                }}
              >
                Upload Excel
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
      <h1 style={{ color: "#1E90FF" }}>
        <b>{id ? "Update" : "Add"} Product</b>
      </h1>
      <Row gutter={25}>
        <Col span={8}>
          <Form layout="vertical" form={form} name="control-hooks">
            <Form.Item
              label="Category"
              rules={[
                {
                  required: true,
                  message: "Missing Product Category",
                },
              ]}
            >
              <Select
                placeholder="Select Category"
                value={category}
                name="category"
                onChange={onCategoryChange}
                disabled={categoryVisibility}
              >
                {id
                  ? ""
                  : categories?.map((item, key) => (
                      <Option key={key} value={item._id}>
                        {item.name}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
            <Form.Item
              //  name="pr_descripition"
              label="Item Code"
              rules={[
                {
                  required: true,
                  message: "Missing Item Code",
                },
              ]}
            >
              <Input
                onChange={handleItemCode}
                name="itemCode"
                value={itemCode}
              />
            </Form.Item>
            <Form.Item
              label="Product Name"
              rules={[
                {
                  required: true,
                  message: "Missing Product Name",
                },
              ]}
            >
              <Input onChange={handleName} name="name" value={name} />
            </Form.Item>

            <Form.Item
              label="Unit of Measure"
              rules={[
                {
                  required: true,
                  message: "Missing Product Quantity Measurment",
                },
              ]}
            >
              {id ? (
                <Select
                  placeholder="Select quantity"
                  mode="multiple"
                  allowClear
                  name="qauntity"
                  value={qauntity}
                  onChange={handleQauntity}
                >
                  {quantityFieldsOnUpdate &&
                    quantityFieldsOnUpdate.length > 0 &&
                    quantityFieldsOnUpdate.map((item, key) => (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    ))}
                </Select>
              ) : (
                <Select
                  placeholder="Select quantity"
                  name="qauntity"
                  value={qauntity}
                  onChange={handleQauntity}
                  mode="multiple"
                  allowClear
                >
                  {qauntityFields.map((item, key) => (
                    <option key={key} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                onClick={id ? updateProductOnclick : handleProductSubmit}
                style={{
                  backgroundColor: "#1E90FF",
                  color: "white",
                  width: "200px",
                }}
              >
                {id ? "Update" : "Add New"} Product
              </Button>
            </Form.Item>
            <Form.Item style={{ marginTop: "-15px", textAlign: "left" }}>
              <Button
                style={{
                  backgroundColor: "#a5b4fc",
                  color: "white",
                  width: "200px",
                }}
                onClick={() => setOpenModal(true)}
              >
                Upload Products from Excel
              </Button>

              {/* <Button onClick={handleUpload}>Import Product</Button> */}
            </Form.Item>
          </Form>
        </Col>
        <Col span={16}>
          <Row gutter={5}>
            {id
              ? fields.map((item, index) => (
                  <Col span={12}>
                    <Form
                      layout="vertical"
                      form={form}
                      name="specifications"
                      style={{
                        width: 300,
                      }}
                    >
                      <Form.Item label={item.name}>
                        <Input
                          key={index}
                          name={item.name}
                          value={editProductData[index]}
                          onChange={(e) =>
                            handleEditedProductData(index, e.target.value)
                          }
                        />
                      </Form.Item>
                    </Form>
                  </Col>
                ))
              : ""}

            {id
              ? ""
              : fields.map((item) => (
                  <Col span={12}>
                    <Form
                      layout="vertical"
                      form={form}
                      name="specifications"
                      style={{
                        width: 300,
                      }}
                    >
                      <Form.Item name={item} label={item}>
                        <Input
                          name={item}
                          value={item}
                          onChange={handleProductData}
                        />
                      </Form.Item>
                    </Form>
                  </Col>
                ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default App;
