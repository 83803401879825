import axios from 'axios';

// Create an instance of Axios with default configuration
const instance = axios.create({
  baseURL: 'https://erp.marnamanufacturing.com',
  // baseURL: 'https://192.168.100.90:5000',
  // baseURL: 'https://127.0.0.1:5000'
});
// Add an interceptor for requests
instance.interceptors.request.use(
  (config) => {
    // Retrieve token from localStorage
    const token = localStorage.getItem('token');
    // Add the token to the request headers
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add an interceptor for responses
instance.interceptors.response.use(
  (response) => {
    // Modify the response data here
    return response;
  },
  (error) => {
    // Handle response error
    return Promise.reject(error);
  }
);

export default instance;