import {
  Input,
  Row,
  Col,
  Form,
  Button,
  Modal,
  Space,
  Select,
  Tooltip,
} from "antd";
import TableAl from "../../components/tables/table";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import {
  getuserReducer,
  updateUserDataReducer,
  deleteUSerReducer,
  undoUserReducer,
  resetPasswordReducer,
  getuserReducerForActive,
  getuserReducerForRemoved,
} from "../../store/userReducer";
import React, { useEffect, useState, useRef } from "react";
import {
  CaretRightOutlined,
  PicRightOutlined,
  DeleteOutlined,
  EditOutlined,
  UndoOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
const ViewAl = () => {
  const [form] = Form.useForm();
  const navigate = useHistory();
  const { users, isLoading, isError } = useSelector((state) => state.user);
  const { crdentialDataForOne } = useSelector((state) => state.login);
  const [userId, setUserId] = useState([]);

  const [statuss, setStatus] = useState("");
  const [actionss, setActionss] = useState("");

  const [password, setPassword] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    setActionss(true);
    setStatus(false);
    dispatch(getuserReducerForActive()); /// this must be changed to active users
  }, []);

  const handlUserSatuts = (event) => {
    if (event === "active") {
      setActionss(true);
      setStatus(false);
      dispatch(getuserReducerForActive());
    } else if (event === "removed") {
      setActionss(false);
      setStatus(true);
      dispatch(getuserReducerForRemoved());
    }
  };

  const editUserData = (row) => {
    navigate.push("/userRegistration/" + row._id);
    dispatch(updateUserDataReducer(row));
  };

  const deleteUserData = (row) => {
    setUserId(row._id);
    Modal.confirm({
      centered: true,
      okCancel: "Cancle",
      okText: "Delete",
      onOk: () => {
        crdentialDataForOne._id == row._id
          ? toast.error("You are deleting yourself")
          : dispatch(deleteUSerReducer({ id: row._id }));
      },
      title: "Confirmation",
      content: "Are you sure to delete this Staff Member",
    });
  };
  const activateUser = (row) => {
    setUserId(row._id);
    Modal.confirm({
      centered: true,
      okCancel: "Cancle",
      okText: "Activate",
      onOk: () => dispatch(undoUserReducer({ id: row._id })),
      title: "Confirmation",
      content: "Are you sure to Undo this Staff Member",
    });
  };
  const undoUser = () => {};

  const displayModal = (row) => {
    setUserId(row?._id);
    setOpenModal(true);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const resetUserPassword = () => {
    dispatch(
      resetPasswordReducer({
        user_id: userId,
        password: password,
      })
    );
    setOpenModal(false);
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  //

  const colummmWarehouseAdmin = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "Keyyy",
      ...getColumnSearchProps("first_name"),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "Ippp",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "gggg",
    },

    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "ph",
      // render: (text, record) => <span>{record.address?.city}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "em",
    },
  ];

  const colummmAdmin = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "Keyyy",
      ...getColumnSearchProps("first_name"),
    },
    {
      title: "Father Name",
      dataIndex: "last_name",
      key: "Ippp",
      ...getColumnSearchProps("last_name"),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "gggg",
    },

    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "ph",
      ...getColumnSearchProps("phone"),
      // render: (text, record) => <span>{record.address?.city}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "em",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Warehouse",
      render: (text, record) => (
        <span>{record.role == "admin" ? "Admin" : record.warehouse?.name}</span>
      ),
    },
    {
      title: "Actions",
      width: "15%",
      render: (text, record) => (
        <span>
          {actionss && (
            <Tooltip title="Edit Staff">
              <Button
                style={{ marginRight: "5px" }}
                onClick={() => editUserData(record)}
                icon={<EditOutlined />}
              ></Button>
            </Tooltip>

            //
            //   <Tooltip title="Tooltip Text">
            //   <Button type="primary">Hover Me</Button>
            // </Tooltip>
            //
          )}
          {actionss && (
            <Tooltip title="Delete Staff">
              <Button
                style={{ marginRight: "5px" }}
                onClick={() => {
                  deleteUserData(record);
                }}
                icon={<DeleteOutlined />}
              ></Button>
            </Tooltip>
          )}
          {statuss && (
            <Tooltip title="Activate staff">
              <Button
                style={{ marginRight: "5px" }}
                className="ml-2"
                icon={<UndoOutlined />}
                onClick={() => activateUser(record)}
              ></Button>
            </Tooltip>
          )}
          {actionss && (
            <Tooltip title="Reset password">
              <Button
                style={{ marginRight: "5px" }}
                onClick={() => displayModal(record)}
                className="ml-2"
                icon={<PlusCircleOutlined />}
              ></Button>
            </Tooltip>
          )}
        </span>
      ),
    },
  ];

  return (
    <div>
      <input type="text" value={userId} hidden />
      <div>
        <Modal
          centered
          onOk={resetUserPassword}
          onCancel={() => setOpenModal(false)}
          title="Reset Password"
          visible={openModal}
        >
          <Row gutter={5}>
            <Col xs={24} lg={24}>
              <Form
                layout="vertical"
                form={form}
                name="control-hooks"
                style={{
                  width: 300,
                }}
              >
                <Form.Item className="capitalize">
                  <Input
                    name="password"
                    value={password}
                    onChange={handlePassword}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
      <div>
        <p
          className="text-xl mb-2"
          style={{
            color: "#1E90FF",
          }}
        >
          <b>List of Staff members</b>
        </p>
      </div>
      <div>
        <Form
          name="complex-form"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          className="mb-2"
        >
          <div>
            {crdentialDataForOne?.role == "admin" ? (
              <Row gutter={20}>
                <Col xs={24} sm={24} lg={8}>
                  <Input className="ml-2 " placeholder="Search" />
                </Col>
                <Col xs={24} sm={24} lg={8}>
                  <Select
                    className=" w-full"
                    onChange={handlUserSatuts}
                    placeholder="Staff Member Status"
                  >
                    <option value="active" selected>
                      Active
                    </option>
                    <option value="removed">Removed</option>
                  </Select>
                </Col>
                <Col xs={24} sm={24} lg={8}>
                  <Button
                    onClick={() => navigate.push("/userRegistration")}
                    className="justify-end bg-blue-300"
                    style={{
                      backgroundColor: "#1E90FF",
                      color: "white",
                    }}
                  >
                    Add New Staff
                  </Button>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </div>
        </Form>
      </div>
      <TableAl
        columnnn={
          crdentialDataForOne.role == "admin"
            ? colummmAdmin
            : colummmWarehouseAdmin
        }
        isLoadinggg={isLoading}
        dataaa={users}
      />
    </div>
  );
};
export default ViewAl;
