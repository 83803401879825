import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { loginUsr, logout } from "../services/authAPI";
import { toast } from "react-toastify";
import { message } from "antd";

const intialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: null,
  isLoggedIn: false,
  crdentialDataForOne: {},
};

export const createLoginReducer = createAsyncThunk(
  "auth/login",
  async (crdntialData, thunkApii) => {
    try {
      return await loginUsr(crdntialData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApii.rejectWithValue(message);
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState: intialState,
  reducers: {
    logoutInternal: (state) => {
      state.isLoggedIn = false;
    },
  },

  extraReducers: (loginDat) => {
    loginDat
      .addCase(createLoginReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createLoginReducer.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.isLoggedIn = true;
        localStorage.setItem("token", action?.payload?.data?.token);
        state.crdentialDataForOne = action.payload?.data;
      })
      .addCase(createLoginReducer.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export const isSuccess = (state) => state.login.isSuccess;
export const isError = (state) => state.login.isError;
export const isLoading = (state) => state.login.isLoading;
export const isLoggedIn = (state) => state.login.isLoggedIn;
export const loginn = (state) => state.login.crdntialData;

export const crdentialDataForOne = (state) => state.login.crdentialDataForOne;
export const { logoutInternal } = loginSlice.actions;
export default loginSlice.reducer;

export const categories = (state) => state.category.categories;
