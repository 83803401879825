import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../components/tables/table";
import { useParams, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCategoryReducerForActive } from "../../store/categoryReducers";
import { getActiveWarehouseReducer } from "../../store/warehouseReducer";
import {
  getAllTransactionReducer,
  getDailyTransactionReducer,
  getRangeTransactionReducer,
  searcheTransactionReducer,
  editTransactionReducer,
  updateTransaction,
} from "../../store/transactionReducers";
// import { ConvertDate } from "../../utils/moment";
import { ConvertDate } from "../../utils/moment";
import { Row, Select, Col, Input, Button, Checkbox, Modal, Form } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
const { Option } = Select;
const ProductReport = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useHistory();

  const dispatch = useDispatch();
  const { transactions, isLoading } = useSelector((state) => state.transaction);
  const { categories } = useSelector((state) => state.category);
  const { warehouses } = useSelector((state) => state.warehouse);
  const { crdentialDataForOne } = useSelector((state) => state.login);

  const [transactionType, setTransactionType] = useState("");
  const [warehose, setWarehose] = useState("");
  const [category, setCategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [daily, setDaily] = useState("daily");
  const [isDisabled, setIsDisabled] = useState(true);
  const [productName, setProductName] = useState("");
  const [disableCondition, setDisabledCondition] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [transaction_type, setTransaction_type] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [eidtedValue, setEditedValue] = useState();
  const [fields, setFields] = useState([]);
  const [trnsactionEditType, setTransactionEditType] = useState("");
  const [visiblity, setVisiblity] = useState(true);
  useEffect(() => {
    dispatch(getActiveWarehouseReducer());
    dispatch(getCategoryReducerForActive());

    console.log("WarehouseeeID", crdentialDataForOne?.warehouse);
    dispatch(
      crdentialDataForOne?.role == "admin"
        ? getAllTransactionReducer()
        : getAllTransactionReducer({
            warehouseId: crdentialDataForOne?.warehouse,
          })
    );
    setWarehose(crdentialDataForOne.warehouse);
    setDisabledCondition(crdentialDataForOne.role == "warehouseAdmin");
  }, []);

  const handleTransactionType = (event) => {
    setTransactionType(event);
  };

  const handleTransactionCategory = (event) => {
    setCategory(event);
  };
  const handleTransactionWarehouse = (event) => {
    setWarehose(event);
  };

  const handleTransactionTime = (event) => {
    setDaily(event);
    if (event == "daily") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  const handleTransactionSTartDate = (event) => {
    setStartDate(event.target.value);
  };
  const handleTransactionEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const handleSearchBy = (event) => {
    setSearchBy(event);
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    if (searchValue.length === 1) {
      console.log("When search by value is empty");
      dispatch(
        crdentialDataForOne?.role == "admin"
          ? getAllTransactionReducer()
          : getAllTransactionReducer({
              warehouseId: crdentialDataForOne.warehouse,
            })
      );
    } else {
      console.log("When search by value is ", searchValue);
      dispatch(
        searcheTransactionReducer({
          searchBy: searchBy,
          query: event.target.value,
        })
      );
    }
  };
  //

  const handleSearchTransaction = () => {
    if (startDate || endDate) {
      if (daily == "daily") {
        setIsDisabled(true);
        dispatch(
          getDailyTransactionReducer({
            date: startDate,
            category,
            warehouse:
              crdentialDataForOne?.role == "admin"
                ? warehose
                : crdentialDataForOne.warehouse,
            type: transactionType,
          })
        );
      } else {
        dispatch(
          getRangeTransactionReducer({
            start_date: startDate,
            end_date: endDate,
            category,
            warehouse:
              crdentialDataForOne?.role == "admin"
                ? warehose
                : crdentialDataForOne.warehouse,
            type: transactionType,
          })
        );
      }
    } else {
      toast.error("please select the transaction date you want to see first");
    }
  };

  const displayModal = (record) => {
    setOpenModal(true);
    navigate.push("/transactionRange/" + record._id);
    dispatch(updateTransaction(record));

    setFields(record?.quantity);
    setEditedValue(record.quantity.map((item) => item.value));
    setTransaction_type(record.transaction_type);
  };

  const handleQuantity = (index, value) => {
    setEditedValue((prevValue) => {
      const newValues = [...prevValue];
      newValues[index] = value;
      return newValues;
    });
  };
  const handleEditTransaction = (record) => {
    const transactionIsVoid = visiblity;
    const update_quantity = fields.map((item, index) => ({
      ...item,
      value: eidtedValue[index],
    }));
    dispatch(editTransactionReducer({ id, update_quantity }));
    setOpenModal(false);
  };
  const handleTransactionEdotType = (event) => {
    setTransactionEditType(event);
    if (event == "void") {
      setVisiblity(false);
    } else {
      setVisiblity(true);
    }
  };
  const handleTransactionTypeVoideOrChangeInValue = () => {};

  console.log("Transactionsss", transactions);

  const columns = [
    {
      title: "Transaction Date",
      width: "max-content",
      render: (text, record) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          <span>{ConvertDate(record?.createdAt)}</span>
        </div>
      ),
    },
    {
      title: "Customer Info",
      render: (text, record) => (
        <ul style={{ listStyleType: "none" }}>
          <li> {record.customer_name}</li>
          <li>{record.customer_phone}</li>
        </ul>
      ),
    },
    {
      title: "Warehouse",
      // width: "8%",
      render: (text, record) => (
        <span>{record.inventory?.warehouse?.name}</span>
      ),
    },

    {
      title: "Product",
      // width: "15%",
      render: (text, record) => <span>{record.inventory?.product?.name}</span>,
    },
    {
      title: "Transaction By",
      // width: "8%",
      render: (text, record) => (
        <span>{record?.user?.first_name + " " + record?.user?.last_name}</span>
      ),
    },
    {
      title: "Transaction Type",
      // width: "8%",
      render: (text, record) => <span>{record.transaction_type}</span>,
    },
    {
      title: "GRN",
      width: "4%",
      render: (text, record) => <span>{record.grn}</span>,
    },
    {
      title: "GIN",
      width: "4%",
      render: (text, record) => <span>{record.gin}</span>,
    },
    {
      title: "Latest",
      // width: "10%",
      render: (text, record) => (
        <>
          {record.latestQuantity?.map((item) => (
            <ul className="flex flex-row">
              <li>
                {item.name} = <b>{item.value}</b>
              </li>
            </ul>
          ))}
        </>
      ),
    },
    {
      title: "Quantity",
      // width: "10%",
      render: (text, record) => (
        <div style={{ marginLeft: "2px" }}>
          {record.quantity?.map((item) => (
            <ul className="flex flex-row">
              <li className="mr-2">
                {item.name} : {item.value}
              </li>
            </ul>
          ))}
        </div>
      ),
    },
    {
      title: "Action",
      render: (text, record) => (
        <span>
          <Button
            icon={<EditOutlined />}
            onClick={() => displayModal(record)}
          />
        </span>
      ),
    },
  ];

  return (
    <div>
      <Modal
        centered
        onOk={() => handleEditTransaction()}
        onCancel={() => setOpenModal(false)}
        title="Edit Transaction"
        visible={openModal}
      >
        <Form
          layout="horizontal"
          form={form}
          name="control-hooks"
          style={{
            width: 300,
          }}
        >
          <p>
            Transaction Type:
            <b>{transaction_type}</b>
          </p>
          {/* <Form.Item name="Transaction Type" label="Transaction Type">
            <Select onChange={handleTransactionEdotType}>
              <option value="changeInValue" visible={visiblity}>
                Change in Value
              </option>
              <option value="void">Void</option>
            </Select>
          </Form.Item> */}
          <div>
            {fields.map((item, index) => (
              <Form.Item label={item.name}>
                <Input
                  key={index}
                  name={item.name}
                  value={eidtedValue[index]}
                  visible={visiblity}
                  onChange={(e) => handleQuantity(index, e.target.value)}
                />
              </Form.Item>
            ))}
          </div>
        </Form>
      </Modal>
      <p
        className="text-xl pt-2 pb-2"
        style={{
          color: "#1E90FF",
        }}
      >
        <b>Transaction Range</b>
      </p>
      <div className="mb-2">
        <Row gutter={20} className="pl-3">
          <Col xs={24} sm={24} lg={6}>
            <Select
              onChange={handleTransactionType}
              className="w-full "
              defaultValue={"All Transactions"}
              style={{ width: 250 }}
              options={[
                //   { value: "", label: "Select Transaction Type", disabled: true },
                { value: "", label: "All Transactions" },
                {
                  name: "Incoming",
                  value: "Incoming",
                  label: "Incoming Transactions",
                },
                { value: "Outgoing", label: "Outgoing Transactions" },
              ]}
            ></Select>
          </Col>
          <Col xs={24} sm={24} lg={7}>
            <Select
              onChange={handleTransactionCategory}
              className="w-full "
              defaultValue={"All Category"}
              style={{ width: 292 }}
            >
              <option value="">All Category</option>
              {categories?.map((item, key) => (
                <Option key={key} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={24} lg={5} className="">
            <div className="flex flex-row w-full">
              <Input
                type="date"
                value={startDate}
                onChange={handleTransactionSTartDate}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} lg={6} className="">
            <Select
              className="w-full"
              value={daily}
              onChange={handleTransactionTime}
              placeholder="Select Date "
              defaultValue="daily"
            >
              <option value="daily">Daily</option>
              <option value="range">Range</option>
            </Select>
          </Col>
        </Row>
        <Row gutter={20} className="pl-3">
          <Col xs={24} sm={24} lg={6}>
            <Select
              onChange={handleTransactionWarehouse}
              className="w-full "
              disabled={disableCondition}
              value={warehose}
              style={{ width: 250 }}
              defaultValue={"All Warehouse"}
            >
              <option value="">All Warehouse</option>
              {warehouses?.map((item, key) => (
                <option key={key} value={item._id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={24} lg={7}>
            <div>
              <Input
                addonBefore={
                  <Select defaultValue="Select" onChange={handleSearchBy}>
                    <option value="product">Product</option>
                    <option value="user">User</option>
                    <option value="gin">GIN</option>
                    <option value="grn">GRN</option>
                    <option value="customer">Customer</option>
                  </Select>
                }
                value={searchValue}
                name="searchValue"
                onChange={handleSearch}
                placeholder={"User, GIN, GRN, Product"}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} lg={5}>
            <Input
              className="flex flex-row w-full"
              type="date"
              value={endDate}
              disabled={isDisabled}
              onChange={handleTransactionEndDate}
            />
          </Col>
          <Col xs={24} sm={24} lg={6}>
            <Button
              className="flex flex-row w-full"
              onClick={handleSearchTransaction}
              style={{
                backgroundColor: "#1E90FF",
                color: "white",
              }}
            >
              Filter Transaction
            </Button>
          </Col>
        </Row>
      </div>
      <CustomTable
        columnnn={columns}
        dataaa={transactions}
        isLoadinggg={isLoading}
      />
    </div>
  );
};
export default ProductReport;
