import {
  createIncomingInventory,
  getIncomingInventoryOnly,
  getInventorysearch,
  getInventorySearchInAllStcok,
  createOutGoingInventory,
  getProductSearch,
  getOutGoingInventory,
  getInventory,
  getAllInventoryInStock,
} from "../services/inventoryApi";
import { Slice, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const intialSate = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  inventory: {},
  inventories: [],
  outGoingInventory: [],
  allInventries: [],
};

export const createInventoryreducer = createAsyncThunk(
  "inventory/create",
  async (inventoryData, thunkApi) => {
    try {
      const res = await createIncomingInventory(inventoryData, thunkApi);
      return res;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getProductsSearchReducer = createAsyncThunk(
  "products/search",
  async (inventoryData, thunkApi) => {
    try {
      const res = await getProductSearch(inventoryData);
      return res;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const createOutgoingInventoryReducer = createAsyncThunk(
  "outgoin/create",
  async (outgoingData, thinkApi) => {
    try {
      const responce = await createOutGoingInventory(outgoingData, thinkApi);
      return responce;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thinkApi.rejectWithValue(message);
    }
  }
);

export const getAllInventoryReducer = createAsyncThunk(
  "get/inventory",
  async (pgNumber, thunkApii) => {
    try {
      const resp = await getInventory(pgNumber);
      return resp;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApii.rejectWithValue(message);
    }
  }
); //
export const getInventoryInAllStockReducer = createAsyncThunk(
  "get/AllInventory",
  async (pgnumber, thunkApii) => {
    try {
      const responce = await getAllInventoryInStock(pgnumber);
      return responce;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApii.rejectWithValue(message);
    }
  }
);

export const getInventorySearchReducer = createAsyncThunk(
  "get/inventory/search",
  async (pgNumber, thunkApii) => {
    try {
      const resp = await getInventorysearch(pgNumber);
      return resp;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApii.rejectWithValue(message);
    }
  }
);
export const serachInventoryInAllStock = createAsyncThunk(
  "search/all",
  async (parm, thunkApi) => {
    try {
      const responce = await getInventorySearchInAllStcok(parm);
      console.log("RESPONCE:", responce);
      return responce;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

const inventorySlice = createSlice({
  name: "incomingInventory",
  initialState: intialSate,
  reducers: {},
  extraReducers: (param) => {
    param
      .addCase(createInventoryreducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createInventoryreducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        toast.success("Inventory Added successufully");
      })
      .addCase(createInventoryreducer.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(createOutgoingInventoryReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createOutgoingInventoryReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        toast.success("Outgoing Inventory");
      })
      .addCase(createOutgoingInventoryReducer.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(getAllInventoryReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllInventoryReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.outGoingInventory = action?.payload?.data;
      })
      .addCase(getAllInventoryReducer.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
      .addCase(getInventoryInAllStockReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInventoryInAllStockReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.allInventries = action?.payload?.data;
      })
      .addCase(getInventoryInAllStockReducer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(getInventorySearchReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInventorySearchReducer.fulfilled, (state, action) => {
        state.isError = false;
        state.isSuccess = true;
        state.isLoading = false;
        state.outGoingInventory = action?.payload?.data?.data;
      })
      .addCase(getInventorySearchReducer.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
        toast.error(action.payload);
      })
      .addCase(getProductsSearchReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductsSearchReducer.fulfilled, (state, action) => {
        state.isError = false;
        state.isSuccess = true;
        state.isLoading = false;
        state.inventories = action.payload.data;
        console.log("Inventory Search for outgoing :", action.payload);
      })
      .addCase(getProductsSearchReducer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(serachInventoryInAllStock.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(serachInventoryInAllStock.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        console.log("Inventory Searcccch:", action.payload?.data?.data);
        state.allInventries = action.payload?.data?.data;
      })
      .addCase(serachInventoryInAllStock.rejected, (state, action) => {
        state.isSuccess = false;
        state.isError = false;
        state.isError = true;
        toast.error(action.payload);
      });
  },
});

export const isError = (state) => state.inventory.isError;
export const isLoading = (state) => state.inventory.isLoading;
export const isSuccess = (state) => state.inventory.isSuccess;

export const inventories = (state) => state.inventory.inventories;
export const outGoingInventory = (state) => state.inventory.outGoingInventory;
export const allInventries = (state) => state.inventory.allInventries;
export default inventorySlice.reducer;
