import axios from "../plugins/axios";

export const createIncomingInventory = async (incomingInvData) => {
  const response = await axios.post("/api/inventory/incoming", incomingInvData);
  console.log("Responce ", response);
  return response;
};

export const getIncomingInventoryOnly = async () => {
  const responss = await axios.get("/api/inventory/incoming");
  return responss;
};

export const getInventorysearch = async (payload) => {
  const response = await axios.post(
    `/api/inventory/search?warehouseId=${payload.warehouseId}&productQuery=${payload.productQuery}`
  );
  return response;
};
export const getInventorySearchInAllStcok = async (payload) => {
  // const responce = await axios.post("/api/inventory/search", payload);
  const response = await axios.post(
    `/api/inventory/search?productQuery=${payload.productQuery}`
  );
  console.log("Responce of Search", response);
  return response;
};

export const getProductSearch = async (payload) => {
  const response = await axios.post("/api/products/search", payload);
  return response;
};

export const createOutGoingInventory = async (outgoingData) => {
  const responce = await axios.post("/api/inventory/outgoing", outgoingData);
  return responce;
};

export const getOutGoingInventory = async () => {
  const respon = await axios.post("/api/inventory");
  return respon;
};

export const getInventory = async (param) => {
  const respon = await axios.post("/api/inventory", param);
  return respon;
};

export const getAllInventoryInStock = async () => {
  const respon = await axios.get("/api/inventory/stock/all");
  return respon;
};
