import { Input, Row, Form, Col, Space, Select, Button, Modal } from "antd";
import TableAl from "../../components/tables/table";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductReducer,
  getActiveProductReducer,
  activateProductReducer,
  getRemovedProductReducer,
  products,
  deleteProductReducer,
  getProductById,
} from "../../store/productReducer";
import {
  getCategoryReducerForActive,
  getCategoryReducerForRemoved,
} from "../../store/categoryReducers";
import { getProductsSearchReducer } from "../../store/inventoryReducer";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  PicRightOutlined,
  DeleteOutlined,
  EditOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { render } from "react-dom";
const { Option } = Select;

const ViewProduct = () => {
  const [form] = Form.useForm();
  const navigate = useHistory();

  const { crdentialDataForOne } = useSelector((state) => state.login);
  const [productQuery, setProductQuery] = useState("");
  const [productSearch, setproductSearch] = useState("");
  const [statuss, setStatus] = useState("");
  const [actionss, setActionss] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const { categories } = useSelector((state) => state.category);
  const { products } = useSelector((state) => state.product);
  const { inventories, isLoading } = useSelector((state) => state.inventory);

  const dispatch = useDispatch();
  const [fields, setFields] = useState([]);
  const [productt, setProduct] = useState();
  const [product_id, setProductId] = useState();

  // const handleEdit = (productData) => {
  //   dispatch(editCategory(categoryData));
  //   navigate("/addCategory/" + categoryData._id);
  // };

  useEffect(() => {
    dispatch(getActiveProductReducer());
    setActionss(true);
  }, []);

  // useEffect(() => {
  //   dispatch(getProductReducer());
  // }, []);

  // const handlCategoryChangee = (ev) => {
  //   if (ev == "active") {
  //     dispatch(getCategoryReducerForActive());
  //   } else if (ev == "removed") {
  //     dispatch(getCategoryReducerForRemoved());

  //   }
  // };

  const onCategoryChange = (value) => {
    setFields([]);
    setProduct([]);
    const selectedCategory = categories.find((item) => item._id == value);
    setFields(selectedCategory.specifications);
    setProduct(value);
  };
  ///
  const handleProductQuery = (event) => {
    setproductSearch(event.target.value);
    dispatch(
      getProductsSearchReducer({
        query: event.target.value,
      })
    );
  };

  const filterDatas = () => {
    let products = [];
    let columns = [];
    let specfication = [];
    for (let i = 0; i < categories?.data?.length; i++) {
      columns = Object.keys(categories?.data[i]).map((key) => ({
        title: key.toUpperCase(),
        dataIndex: key,
        key: key,
      }));
      specfication = Object.keys(
        categories?.data[i].specifications.map((key) => ({
          title: key.toUpperCase(),
          dataIndex: key,
          key: key,
        }))
      );
      products.push({
        ...categories?.data[i].specifications,
        name: categories?.data[i].name,
        description: categories?.data[i].description,
      });
    }
  };
  setTimeout(() => {
    filterDatas();
  }, 500);

  const handleDelete = (productData) => {
    setProductId(productData._id);
    Modal.confirm({
      centered: true,
      okCancel: "Cancle",
      okText: "Delete",
      onOk: () => dispatch(deleteProductReducer({ id: productData._id })),
      title: "Confirmation",
      content: "Are you sure to delete this product",
    });
  };

  const deleteCa = () => {
    dispatch(deleteProductReducer({ id: product_id }));
  };

  const handleActivate = (productData) => {
    Modal.confirm({
      centered: true,
      okCancel: "Cancle",
      okText: "Activate",
      onOk: () => dispatch(activateProductReducer({ id: productData._id })),
      title: "Confirmation",
      content: "Are you sure to Activate this product",
    });
  };

  const handlStatus = (eve) => {
    if (eve === "active") {
      setActionss(true);
      setStatus(false);
      dispatch(getActiveProductReducer());
    } else if (eve === "removed") {
      setActionss(false);
      setStatus(true);
      dispatch(getRemovedProductReducer());
    }
  };

  const editProduct = (productDataOnclick) => {
    navigate.push("/addproduct/" + productDataOnclick._id);
    dispatch(getProductById(productDataOnclick));
  };

  const ellipsisStyle = {
    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const colummm = [
    {
      title: "Item Code",
      width: "10%",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Product Name",
      width: "max-content",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Product Category",
    //   render: (text, record) => <span>{record.category?.name}</span>,
    // },

    {
      title: "specifications",
      render: (text, record) => (
        <ul>
          {record?.specifications?.map((item, index) => (
            <li key={index}>
              <b>{item.name}</b>: {item.value}
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: "Quantity Measurments",
      width: "15%",
      render: (text, record) => (
        <ul>
          {record?.quantity?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      ),
    },
  ];
  if (crdentialDataForOne.role == "admin") {
    colummm.push({
      title: "Actions",
      width: "10%",
      render: (text, record) => (
        <span>
          {actionss && (
            <Button
              onClick={() => editProduct(record)}
              style={{ marginRight: 8 }}
              icon={<EditOutlined />}
            ></Button>
          )}
          {actionss && (
            <Button
              onClick={() => handleDelete(record)}
              icon={<DeleteOutlined />}
            ></Button>
          )}
          {statuss && (
            <Button
              onClick={() => handleActivate(record)}
              icon={<UndoOutlined />}
            ></Button>
          )}
        </span>
      ),
    });
  }
  const columnsWithEllipsis = colummm.map((column) => {
    return {
      ...column,
      ellipsis: true,
      // render: (text, record) => <div style={ellipsisStyle}>{record.name}</div>,
    };
  });
  return (
    <div>
      <p className="text-xl mb-2" style={{ color: "#1E90FF" }}>
        <b>List of Products</b>
      </p>

      <Row gutter={20}>
        <Col xs={24} sm={24} lg={8}>
          <Form.Item name="productname" label="Product Name">
            <Input
              value={productQuery}
              name="productQuery"
              onChange={handleProductQuery}
              placeholder="Search by Product"
            />
          </Form.Item>
          {/* <Form.Item
            name="category"
            rules={[
              {
                required: true,
                message: "Missing Product Category",
              },
            ]}
          >
            <Select
              placeholder="Select Category"
              value={productt}
              name="category"
              onChange={onCategoryChange}
              className="w-full ml-2"
              allowClear
            >
              {categories?.map((item, key) => (
                <Option key={key} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item> */}
        </Col>
        <Col xs={24} sm={24} lg={8}>
          {crdentialDataForOne.role == "admin" && (
            <Form.Item>
              <Select
                className="mr-2"
                onChange={handlStatus}
                placeholder="Select Category Status"
              >
                <option value="active" selected>
                  Active
                </option>
                <option value="removed">Removed</option>
              </Select>
            </Form.Item>
          )}
        </Col>
        <Col xs={24} sm={24} lg={8}>
          {crdentialDataForOne?.role == "admin" && (
            <Button
              onClick={() => navigate.push("/addproduct")}
              className="bg-blue-300"
              style={{
                backgroundColor: "#1E90FF",
                color: "white",
              }}
            >
              Add new product
            </Button>
          )}
        </Col>
      </Row>

      <div>
        {/* <TableAl
          columnnn={colummm}
          isLoadinggg={isLoading}
          dataaa={products?.data}
        /> */}
        <TableAl
          columnnn={columnsWithEllipsis}
          dataaa={productSearch ? inventories : products?.data}
          isLoadinggg={isLoading}
        />
      </div>
    </div>
  );
};
export default ViewProduct;
