import { configureStore } from "@reduxjs/toolkit";

import categoryReducer from "./categoryReducers"
import loginReducer from "./loginReducer"
import userReducerr from "./userReducer"
import inventoryReducer from "./inventoryReducer"
import productReducer from "./productReducer"
import wareHouseReducer from "./warehouseReducer"
import transactionReducer from "./transactionReducers"
export const store = configureStore({
  reducer: {
    user: userReducerr,
    login: loginReducer,
    category: categoryReducer,
    inventory: inventoryReducer,
    product: productReducer,
    warehouse: wareHouseReducer,
    transaction: transactionReducer,

  },
});

