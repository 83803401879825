import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createProduct,
  getProduct,
  updateProduct,
  deleteProduct,
  activateProduct,
  getActiveProduct,
  getRemovedProduct,
  getProductSearch,
} from "../services/productAPI";
import { toast } from "react-toastify";
const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  product: {},
  products: [],
  productsByCategory: [],
  error: null,
};
export const addProductReducer = createAsyncThunk(
  "add/product",
  async (productdata, thunkApi) => {
    try {
      return await createProduct(productdata);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getProductReducer = createAsyncThunk(
  "get/product",
  async (productdataa, thunkApi) => {
    try {
      return await getProduct(productdataa);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getRemovedProductReducer = createAsyncThunk(
  "getRemoved/product",
  async (productdataa, thunkApi) => {
    try {
      return await getRemovedProduct(productdataa);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getActiveProductReducer = createAsyncThunk(
  "get/product",
  async (productdataa, thunkApi) => {
    try {
      console.log("KKKKKK inside the get activate product", productdataa);
      return await getActiveProduct(productdataa);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const updateProductReducer = createAsyncThunk(
  "update/product",
  async (prodata, thunkApi) => {
    try {
      return await updateProduct(prodata);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const deleteProductReducer = createAsyncThunk(
  "delete/product",
  async (prodata, thunkApi) => {
    try {
      return await deleteProduct(prodata);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const activateProductReducer = createAsyncThunk(
  "activate/product",
  async (prodata, thunkApi) => {
    try {
      return await activateProduct(prodata);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);
///
export const getProductsSearchReducer = createAsyncThunk(
  "products/search",
  async (inventoryData, thunkApi) => {
    try {
      const res = await getProductSearch(inventoryData);
      return res;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);
///

const productSlice = createSlice({
  name: "product",
  initialState: initialState,
  reducers: {
    getProductById: (state, action) => {
      state.product = action.payload;
    },

    getAllproductByCategory: (state) => {
      // const selectedCategory = state.products.find(
      //   (item) => item.category == categoryId
      // );
      // state.productsByCategory = selectedCategory;
    },
  },
  extraReducers: (para) => {
    para
      .addCase(addProductReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addProductReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success("Product Added Successfully");
      })
      .addCase(addProductReducer.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.payload;
        toast.success(action.payload);
      })
      .addCase(getProductReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.products = action.payload;
      })
      .addCase(getProductReducer.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(getRemovedProductReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRemovedProductReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.products = action.payload;
      })
      .addCase(getRemovedProductReducer.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(updateProductReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProductReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        toast.success("Product updated successfully");
      })
      .addCase(updateProductReducer.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(deleteProductReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProductReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;

        console.log("Product");
        const recordProduct = action.payload;
        console.log("Producttt", recordProduct);
        // state.products = state.products?.filter(
        //   (record) => record._id !== recordProduct.data?._id
        // );
        toast.success("Product deleted successfully");
      })
      .addCase(deleteProductReducer.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(activateProductReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activateProductReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        const recordPrdocut = action.payload;
        // state.products = state.products?.filter(
        //   (record) => record._id !== recordPrdocut.data?.product?._id
        // );
        toast.success("Product Activated successfully");
      })
      .addCase(activateProductReducer.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        toast.error(action.payload);
      });
  },
});
export const isSuccess = (state) => state.product.isSuccess;
export const isError = (state) => state.product.isError;
export const productsByCategory = (state) => state.product.productsByCategory;
export const isLoading = (state) => state.product.isLoading;
export const { getAllproductByCategory } = productSlice.actions;
export const products = (state) => state.product.products;

export const { getProductById } = productSlice.actions;

export default productSlice.reducer;

//export const {} = productSlice.actions;
