import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    getAllTransactions, getDailyTransactions, getRangeTransactions,
    searcheTransaction, editTransaction
} from "../services/transactions";
import { toast } from "react-toastify";

const intialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    transactions: [],
    transaction: {},
    transactionReport: []
};

export const getAllTransactionReducer = createAsyncThunk(
    "report/all",
    async (crdntialData, thunkApii) => {
        try {
            return await getAllTransactions(crdntialData);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkApii.rejectWithValue(message);
        }
    }
);
export const searcheTransactionReducer = createAsyncThunk(
    "search/transaction",
    async (param, thunkApii) => {
        try {
            return await searcheTransaction(param)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkApii.rejectWithValue(message);
        }
    }
)

export const getDailyTransactionReducer = createAsyncThunk(
    "report/daily",
    async (crdntialData, thunkApii) => {
        try {
            return await getDailyTransactions(crdntialData);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkApii.rejectWithValue(message);
        }
    }
);

export const getRangeTransactionReducer = createAsyncThunk(
    "report/range",
    async (crdntialData, thunkApii) => {
        try {

            return await getRangeTransactions(crdntialData);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkApii.rejectWithValue(message);
        }
    }
);

export const editTransactionReducer = createAsyncThunk(
    "editt/Transaction",
    async (updatedData, thunkApii) => {
        try {
            return await editTransaction(updatedData)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkApii.rejectWithValue(message);
        }
    }
)

const transactioSlice = createSlice({
    name: "login",
    initialState: intialState,
    reducers: {
        updateTransaction: (state, action) => {
            state.transaction = action.payload
            console.log("KIDAY:")
        },

    },

    extraReducers: (builder) => {
        builder
            .addCase(getAllTransactionReducer.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllTransactionReducer.fulfilled, (state, action) => {
                state.isSuccess = true;
                state.isError = false;
                state.isLoading = false;
                if (state.transactions) {
                    state.transactions = action.payload?.data;
                } else {
                    toast.error("There is something error ")
                }
            })
            .addCase(getAllTransactionReducer.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                toast.error(action.payload);
            }).addCase(getDailyTransactionReducer.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDailyTransactionReducer.fulfilled, (state, action) => {
                state.isSuccess = true;
                state.isError = false;
                state.isLoading = false;
                state.transactions = action.payload?.data;
            })
            .addCase(getDailyTransactionReducer.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                toast.error(action.payload);
            })
            .addCase(getRangeTransactionReducer.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getRangeTransactionReducer.fulfilled, (state, action) => {
                state.isSuccess = true;
                state.isError = false;
                state.isLoading = false;
                state.transactions = action.payload?.data;
            })
            .addCase(getRangeTransactionReducer.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                toast.error(action.payload);
            }).addCase(searcheTransactionReducer.pending, (state) => {
                state.isLoading = true
            }).addCase(searcheTransactionReducer.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.isSuccess = true
                state.transactions = action.payload?.data
            }).addCase(searcheTransactionReducer.rejected, (state, action) => {
                state.isError = true
                state.isSuccess = false
                state.isLoading = false
                toast.error(action.payload)
            }).addCase(editTransactionReducer.pending, (state) => {
                state.isLoading = true
            }).addCase(editTransactionReducer.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.transactions = action.payload?.data
                //state.toast("Transaction Edited Successfully")
            }).addCase(editTransactionReducer.rejected, (state, action) => {
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
            })
    },
});

export const isSuccess = (state) => state.transaction.isSuccess;
export const isError = (state) => state.transaction.isError;
export const isLoading = (state) => state.transaction.isLoading;
export const transactions = (state) => state.transaction.transactions;

export const { updateTransaction } = transactioSlice.actions
export default transactioSlice.reducer;