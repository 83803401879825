import { Input, Row, Col, Button, Modal, Select, Form } from "antd";
import TableAl from "../../components/tables/table";
import { useDispatch, useSelector } from "react-redux";
import {
  editCategory,
  deleteCategoryReducer,
  undoCategoryReducer,
  getCategorySearchReducer,
  getCategoryReducerForActive,
  getCategoryReducerForRemoved,
} from "../../store/categoryReducers";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  PicRightOutlined,
  DeleteOutlined,
  EditOutlined,
  UndoOutlined,
} from "@ant-design/icons";

const ViewAl = () => {
  const navigate = useHistory();

  const [statuss, setStatus] = useState("");
  const [actionss, setActionss] = useState("");
  const [categoryQuary, setcategoryQuary] = useState("");
  const { crdentialDataForOne } = useSelector((state) => state.login);

  const { categories, isLoading } = useSelector((state) => state.category);
  const [category_id, setCategoryId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [categoryForExcel, setCategoryForExcel] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategoryReducerForActive());
    setActionss(true);
  }, []);

  // useEffect(() => {
  //   dispatch(getCategoryReducerForActive());
  // });

  const handleEdit = (categoryData) => {
    dispatch(editCategory(categoryData));
    navigate.push("/addCategory/" + categoryData._id);
  };

  const handleDelete = (categoryData) => {
    setCategoryId(categoryData._id);
    Modal.confirm({
      centered: true,
      okCancel: "Cancle",
      okText: "Delete",
      onOk: () => dispatch(deleteCategoryReducer({ id: categoryData._id })),
      title: "Confirmation",
      content: "Are you sure to delete this Category",
    });
  };

  const handlSatuts = (ev) => {
    if (ev === "active") {
      setActionss(true);
      setStatus(false);
      dispatch(getCategoryReducerForActive());
    } else if (ev === "removed") {
      setActionss(false);
      setStatus(true);
      dispatch(getCategoryReducerForRemoved());
    }
  };

  const activateCategory = (row) => {
    setCategoryId(row._id);
    Modal.confirm({
      centered: true,
      okCancel: "Cancle",
      okText: "Activate",
      onOk: () => dispatch(undoCategoryReducer({ id: row._id })),
      title: "Confirmation",
      content: "Are you sure to Undo this Category",
    });
  };
  const displayModal = () => {
    setOpenModal(true);
  };
  const undoCategory = () => {
    dispatch(undoCategoryReducer({ id: category_id }));
  };
  const handleCategorySearch = (event) => {
    setcategoryQuary(event.target.value);
    console.log("Product Category");
    dispatch(getCategorySearchReducer({ quary: categoryQuary }));
  };

  const colummm = [
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "description",
    //   dataIndex: "description",
    //   key: "description",
    // },
    {
      title: "Specfications",
      render: (text, record) => (
        <ul>
          {record?.specifications?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      ),
    },
  ];

  ///
  if (crdentialDataForOne?.role == "admin") {
    colummm.push({
      title: "Action",
      render: (text, record) => (
        <span className="flex flex-row justify-center">
          {actionss && (
            <Button
              style={{ marginRight: 8 }}
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
            ></Button>
          )}
          {actionss && (
            <Button
              icon={<DeleteOutlined />}
              onClick={() => handleDelete(record)}
            ></Button>
          )}
          {statuss && (
            <Button
              className="ml-2"
              icon={<UndoOutlined />}
              onClick={() => activateCategory(record)}
            ></Button>
          )}
        </span>
      ),
    });
  }
  //
  const handleCategoryChangeForExcelDownload = (event) => {
    setCategoryForExcel(event);
  };
  const handleExcelDownloadTemplate = () => {
    const url = `https://erp.marnamanufacturing.com/api/inventory/download/template/${categoryForExcel}`;
    // const url = `https://127.0.0.1:5000/api/inventory/download/template/${categoryForExcel}`;

    window.open(url);
  };
  //

  return (
    <div>
      <Modal
        centered
        onOk={handleExcelDownloadTemplate}
        onCancel={() => setOpenModal(false)}
        title="Download Excel Template"
        visible={openModal}
      >
        <Form>
          <Form.Item label="Product Category">
            <Select
              placeholder="Select Category"
              name="category"
              value={categoryForExcel}
              onChange={handleCategoryChangeForExcelDownload}
            >
              {categories?.map((item, key) => (
                <option key={key} value={item._id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <p
        className="text-xl mb-2"
        style={{
          color: "#1E90FF",
        }}
      >
        <b>List of Categories</b>
      </p>
      <Row gutter={20}>
        <Col xs={24} sm={24} lg={6}>
          <Input
            className="ml-2 w-full"
            placeholder="Search"
            onChange={handleCategorySearch}
          />
        </Col>

        {crdentialDataForOne.role == "admin" && (
          <>
            <Col xs={24} sm={24} lg={6}>
              <Select
                className="w-full mr-2"
                onChange={handlSatuts}
                placeholder="Select Category Status"
              >
                <option value="active" selected>
                  Active
                </option>
                <option value="removed">Removed</option>
              </Select>
            </Col>
            <Col xs={24} sm={24} lg={6}>
              <Button
                onClick={() => navigate.push("/addCategory")}
                className="bg-blue-300"
                style={{
                  backgroundColor: "#1E90FF",
                  color: "white",
                }}
              >
                Add New Category
              </Button>
            </Col>
          </>
        )}
        <Col xs={24} sm={24} lg={6}>
          <Button
            style={{
              backgroundColor: "#1E90FF",
              color: "white",
            }}
            onClick={displayModal}
          >
            Download Template
          </Button>
        </Col>
      </Row>
      <div className="mt-3">
        <TableAl
          columnnn={colummm}
          isLoadinggg={isLoading}
          dataaa={categories}
        />
      </div>
    </div>
  );
};
export default ViewAl;
