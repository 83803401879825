import React, { useEffect, useState } from "react";
import {
    Button,
    Row,
    Upload,
    message,
    Col,
    Form,
    Input,
    Select,
    Modal,
} from "antd";
import { SearchOutlined } from "@ant-design/icons"

import { useDispatch, useSelector } from "react-redux";
import {
    getInventorySearchReducer,
    getInventoryReducer,
    getProductsSearchReducer,
    getInventoryInAllStockReducer,
    getAllInventoryReducer,
    serachInventoryInAllStock
} from "../../store/inventoryReducer";
import { getCategoryReducerForActive } from "../../store/categoryReducers"
import { getActiveWarehouseReducer } from "../../store/warehouseReducer"
import TableA from "../../components/tables/table";
import Item from "antd/es/list/Item";
import { toast } from "react-toastify";
import axios from "axios";
import { saveAs } from "file-saver";
const { Option } = Select;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};



const InventoryInAllStock = () => {
    const [form] = Form.useForm();
    const [openModal, setOpenModal] = useState(false)
    const [warehousesIds, setWarehouse] = useState([])
    const [categoriesIds, setCategory] = useState([])

    const [searchBy, setSearchBy] = useState('')
    const [searchValue, setSearchValue] = useState('')

    const { outGoingInventory } = useSelector((state) => state.inventory);
    const { allInventries, isLoading } = useSelector((state) => state.inventory);
    const { categories } = useSelector((state) => state.category)
    const { warehouses } = useSelector((state) => state.warehouse)

    const [selectedCategory, setSelectedCategory] = useState([])
    const [selectedWarehouse, setSelectedWarehouse] = useState([])
    const dispatch = useDispatch();

    //const tableData = ()=>{if()}

    useEffect(() => {
        //    dispatch(getProductReducer());
        //    dispatch(getCategoryReducerForActive());
        //    dispatch(getActiveWarehouseReducer());
        dispatch(getActiveWarehouseReducer())
        dispatch(getCategoryReducerForActive())
        // dispatch(getInventoryInAllStockReducer());
        dispatch(getAllInventoryReducer())
    }, []);

    let dataSource = []
    dataSource = Object.keys(allInventries).map((key) => ({
        key,
        ...allInventries[key],
    }));

    const displayModal = () => [
        setOpenModal(true)
    ]
    const handleWarhouseChange = (event) => {
        const selectedValues = Array.from(event.target.selectedWarehouse,
            (option) => option.value)
        setSelectedWarehouse(selectedValues)
    }
    const handleWarhouseChangee = (event) => [
        setWarehouse(event)
    ]
    const handleCategoryChange = (event) => {
        setCategory(event);
    }

    const handleSearch = (event) => {
        setSearchValue(event.target.value)
        dispatch(serachInventoryInAllStock({ productQuery: event.target.value }))
    }

    const handleDownload = ({ url, requestBody }) => {
        if (warehousesIds == "" && categoriesIds == "") {
            requestBody = { warehousesIds: [], categoriesIds: [] }
        } else if (warehousesIds == "" && categoriesIds != "") {
            requestBody = { warehousesIds: [], categoriesIds: [categoriesIds] }
        } else if (warehousesIds != "" && categoriesIds == "") {
            requestBody = { warehousesIds: [warehousesIds], categoriesIds: [] }
        } else if (warehousesIds != "" && categoriesIds != "") {
            requestBody = { warehousesIds: warehousesIds, categoriesIds: [categoriesIds] }
        }
        const encodedRequestBody = encodeURIComponent(JSON.stringify(requestBody));
        const downloadUrl = `${`https://erp.marnamanufacturing.com/api/inventory/stock/export/excel`}?data=${encodedRequestBody}`;
        window.open(downloadUrl, '_blank');
        setOpenModal(false)
    };

    const columnsA = [
        {
            title: "Item Code",
            width: "10%",
            render: (text, record) => <span>{record?.product?.code}</span>
        },
        {
            title: "Product Name",
            width: "20%",
            render: (text, record) => <span>{record?.product?.name}</span>,
        },
        {
            title: "Product Specifications",
            render: (text, record) => (
                <span>
                    {record.product?.specifications?.map((item) => (
                        <li>
                            {item.name}: <b>{item.value}</b>
                        </li>
                    ))}
                </span>
            )
        },
        // {
        //     title: "Product Category",
        //     width: "15%",
        //     render: (text, record) => <span>{record?.product?.productCategory?.name}</span>,
        // },
        {
            title: "Quantity",
            width: "20%",
            render: (text, record) => (
                <span>
                    {record.quantity?.map((item) => (
                        <li>
                            {item.name}: <b>{item.value}</b>
                        </li>
                    ))}
                </span>
            ),
        },
    ];
    return (
        <div className="ml-10">
            <Modal
                centered
                title="Export to Excel"
                onOk={handleDownload}
                onCancel={() => setOpenModal(false)}
                visible={openModal}
            >
                <Form>
                    <Form.Item label="Warehouse">
                        <Select
                            placeholder="Select Warehouse"
                            name="warehouse"
                            //value={warehousesIds}
                            onChange={handleWarhouseChangee}
                        >
                            <Option value="">All Warehouses</Option>
                            {warehouses?.map((item, key) => (
                                <option key={key} value={item._id}>
                                    {item.name}
                                </option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Category">
                        <Select
                            placeholder="Select CAtegory"
                            name="category"
                            onChange={handleCategoryChange}
                        >
                            <option value="">All Categories</option>
                            {categories?.map((item, key) => (
                                <Option key={key} value={item._id}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <div>
                <p className="text-xl mb-4" style={{
                    color: "#1E90FF",
                }}><b>Inventory inAll Warehouses</b></p>

                {/* <Row gutter={5}> */}
                <Form
                    layout="vertical"
                    form={form}
                    name="control-hooks"
                    style={{
                        maxWidth: 600,
                    }}
                >
                    <Row>
                        <Col xs={24} sm={24} lg={12}>
                            <Input addonBefore={<SearchOutlined />}
                                name="search"
                                value={searchValue}
                                onChange={handleSearch}
                                placeholder="Search"
                            >
                            </Input>
                        </Col>
                        <Col xs={24} sm={24} lg={12}>
                            <Button style={{
                                backgroundColor: "#1E90FF",
                                color: "white",
                                textAlign: "left"
                            }}
                                onClick={displayModal}
                            >Export</Button>
                        </Col>
                    </Row>

                </Form>
                <div>
                    <div>
                        <TableA columnnn={columnsA} dataaa={dataSource} isLoadinggg={isLoading} />
                        {/* <TableA columnnn={columnsA} dataaa={outGoingInventory} isLoadinggg={isLoading} /> */}
                    </div>
                </div>

            </div>
        </div >
    );
};
export default InventoryInAllStock;
