import { useNavigate, Link } from "react-router-dom";
import {
  getAllInventoryReducer,
  getInventoryInAllStockReducer,
} from "../../store/inventoryReducer";
import { getActiveProductReducer } from "../../store/productReducer";
import { getDailyTransactionReducer } from "../../store/transactionReducers";
import TableAl from "../../components/tables/table";
import { Layout, Menu, Card, Row, Col, Statistic, Table } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
const { Header, Sider, Content } = Layout;

const App = () => {
  const navigate = useHistory();
  const { crdentialDataForOne } = useSelector((state) => state.login);
  const { categories } = useSelector((state) => state.login);
  const { transactions } = useSelector((state) => state.transaction);
  const { allInventries } = useSelector((state) => state.inventory);

  const { products } = useSelector((state) => state.product);

  const { warehouses } = useSelector((state) => state.warehouse);

  const [dailyDate, setDailyDate] = useState("");
  const dispatch = useDispatch("");
  useEffect(() => {
    setDailyDate("");
    dispatch(getDailyTransactionReducer());
    dispatch(getInventoryInAllStockReducer());
    dispatch(getActiveProductReducer());
  }, []);

  const countProduct = () => {
    //
  };

  let dataSource = [];
  dataSource = Object.keys(allInventries).map((key) => ({
    key,
    ...allInventries[key],
  }));

  const columnsA = [
    {
      title: "Item Code",
      width: "10%",
      render: (text, record) => <span>{record?.product?.code}</span>,
    },
    {
      title: "Product Name",
      width: "20%",
      render: (text, record) => <span>{record?.product?.name}</span>,
    },
    // {
    //     title: "Product Category",
    //     width: "15%",
    //     render: (text, record) => <span>{record?.product?.category?.name}</span>,
    // },

    {
      title: "Quantity",
      width: "20%",
      render: (text, record) => (
        <span>
          {record.quantity?.map((item) => (
            <li>
              {item.name}: <b>{item.value}</b>
            </li>
          ))}
        </span>
      ),
    },
  ];

  return (
    <div>
      {/* <Header className="text-xl pt-2" style={{ background: "#fff" }}>
          Welcome To Marna
        </Header> */}
      <Row gutter={16}>
        <Col span={8}>
          <Card className="bg">
            <Statistic title="Spare part" />
          </Card>
          <Card>
            <Statistic title=" Aluminium " />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic className="" title=" Galvanized " />
          </Card>
          <Card>
            <Statistic title=" Tile " className="" />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic title="Daily Transaction" />
          </Card>
          <Card>
            <Statistic title="Total Other Products" />
          </Card>
        </Col>
      </Row>
      <Card style={{ marginTop: "16px" }}>
        {/* <Statistic title="Daily Transaction" /> */}
        <h2>Inventory in all Warehouses</h2>
        <Table columns={columnsA} dataSource={dataSource} />
      </Card>
    </div>
  );
};

export default App;
