import axios from "../plugins/axios";

export const getAllTransactions = async (warehouseId) => {

    console.log("WarehouseIDDD", warehouseId)
    const encodedData = new URLSearchParams(warehouseId).toString();
    const response = await axios.get(`/api/transactions?${encodedData}`);
    return response
}

export const getDailyTransactions = async (payload) => {
    const response = await axios.post("/api/transactions/daily", payload);
    return response;
}

export const getRangeTransactions = async (payload) => {
    const response = await axios.post("/api/transactions/range", payload);
    return response;
}
export const searcheTransaction = async (payload) => {
    const response = await axios.post("/api/transactions/search", payload);
    return response;
}

export const editTransaction = async (payload) => {
    const responce = await axios.post("/api/transactions/edit/" + payload.id, payload)
    console.log("EDIt Transaction DATAS:", responce)
    return responce
}