import { SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { Button, Input, Space, Table } from "antd";
const App = ({ columnnn, dataaa, isLoadinggg }) => {
  const [tableDimensions, setTableDimentions] = useState({
    width: "100vw",
    height: "100vh",
  });
  useEffect(() => {
    const handleResize = () => {
      setTableDimentions({
        width: `${window.innerWidth}px`,
        height: `${window.innerHeight}px`,
      });
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="table-container">
      <Table
        size="large"
        columns={columnnn}
        dataSource={dataaa}
        scroll={{ x: "auto" }}
        loading={isLoadinggg}
        pagination={{
          pageSize: 10,
          pageSizeOptions: ["10", "20", "30", "40"],
        }}
        style={{
          width: "auto",
          height: "auto",
        }}
      />
    </div>
  );
};
export default App;
