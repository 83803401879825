import { useState, React, useRef } from "react";
import { Button, Row, Col, Space, Form, Input, Select, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  BorderBottomOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import {
  createCategReducer,
  updateCategoryReducer,
} from "../../store/categoryReducers";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const initialState = {
  categoryy: "",
  cat_description: "",
};

const AddNewCategory = () => {
  const { id } = useParams();
  const { isSuccess, isError, category } = useSelector(
    (state) => state.category
  );

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    dispatch(
      createCategReducer({
        name: name,
        description: description,
        specifications: values.specfications,
        quantity_measurement: values.quantity_measurement,
      })
    );
  };
  const onReset = () => {
    form.resetFields();
  };

  const [showInput, setShowInput] = useState(false);
  const [name, setCategory] = useState("");
  const [description, SetDescrpition] = useState("");
  const [inputValue, setInputValue] = useState(initialState);
  const handleClick = () => {
    setShowInput(true);
  };

  // const formRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (id) {
        setCategory(category?.payload?.name);
        SetDescrpition(category?.payload?.description);
        form.setFieldsValue({ name: category?.payload?.name });
        form.setFieldsValue({ description: category?.payload?.description });
        form.setFieldsValue({
          specfications: category?.payload?.specifications,
        });
        form.setFieldsValue({
          quantity_measurement: category?.payload?.quantity_measurement,
        });
      }
    }, 400);
  }, []);

  const updateCategory = (values) => {
    dispatch(
      updateCategoryReducer({
        id,
        name: name,
        description: description,
        specifications: values.specfications,
        quantity_measurement: values.quantity_measurement,
      })
    );
  };

  const handleCategory = (ev) => {
    setCategory(ev.target.value);
  };
  const handleDescrpition = (ev) => {
    SetDescrpition(ev.target.value);
  };
  const addCategory = () => {
    //
  };

  return (
    <div className="">
      <Typography
        className="text-xl pt-2 mb-2"
        style={{
          color: "#1E90FF",
        }}
      >
        <b>{id ? "Edit" : "Add"} Product Category</b>
      </Typography>
      <div className="flex justify-center">
        <Row>
          <Col span={24}>
            {/*the daynamic input starts here*/}
            <Form
              layout="horizental"
              form={form}
              onFinish={id ? updateCategory : onFinish}
              name="control-hooks"
              style={{
                width: 300,
              }}
            >
              <Form.Item
                name="name"
                label="Category Name"
                rules={[
                  {
                    required: true,
                    message: "Missing Category Name",
                  },
                ]}
              >
                <Input name="name" value={name} onChange={handleCategory} />
              </Form.Item>
              <Form.Item
                name="description"
                label="Category Description"
                rules={[
                  {
                    required: true,
                    message: "Missing Category Description",
                  },
                ]}
              >
                <Input
                  name="description"
                  value={description}
                  onChange={handleDescrpition}
                />
              </Form.Item>
              <Form.List name="specfications">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          style={{ width: 300 }}
                          {...restField}
                          name={[name]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Attribute" + (key + 1),
                            },
                          ]}
                        >
                          <Input placeholder={"Attribute" + (key + 1)} />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                        style={{
                          backgroundColor: "#a5b4fc",
                          color: "white",
                        }}
                      >
                        Add Category Specification
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Form.List name="quantity_measurement">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          style={{ width: 300 }}
                          {...restField}
                          name={[name]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Attribute" + (key + 1),
                            },
                          ]}
                        >
                          <Input placeholder={"Attribute" + (key + 1)} />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                        style={{
                          backgroundColor: "#a5b4fc",
                          color: "white",
                        }}
                      >
                        Add Quantity Measurment
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Form.Item>
                <Button
                  htmlType="submit"
                  block
                  style={{
                    backgroundColor: "#818cf8",
                    color: "white",
                  }}
                >
                  {id ? "Update Category" : "Add Category"}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default AddNewCategory;
