import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Upload,
  message,
  Col,
  Form,
  Input,
  Select,
  Modal,
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
  createInventoryreducer,
  createOutgoingInventoryReducer,
  getInventorySearchReducer,
  getAllInventoryReducer,
  getInventoryReducer,
  getProductsSearchReducer,
} from "../../store/inventoryReducer";
import {
  FileExcelOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import {
  categories,
  getCategoryReducerForActive,
} from "../../store/categoryReducers";
import { getActiveWarehouseReducer } from "../../store/warehouseReducer";
import {
  getAllproductByCategory,
  getProductReducer,
} from "../../store/productReducer";
import TableA from "../../components/tables/table";
const { Option } = Select;

const OutgoingInventory = () => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    //
  };

  const [fields, setFields] = useState([]);
  const [quantityMeasures, setQuantityMeasures] = useState([]);
  const [quantityData, setQuantityData] = useState([]);
  const [productQuantity, setProductQuantity] = useState();
  const [productId, setProductId] = useState("");
  const [customerName, setCustomerName] = useState();
  const [customerPhoneNo, setCustomerPhone] = useState();
  const [transactionDate, setTransactionDate] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [category, setCategory] = useState("");
  const [productQuery, setProductQuery] = useState("");
  const [warehouseName, setWarhousename] = useState("");
  const [gin, setGin] = useState("");
  const { outGoingInventory, isLoading } = useSelector(
    (state) => state.inventory
  );
  const [disabledCondition, setDisabledCondition] = useState("");
  //
  const { categories } = useSelector((state) => state.category);
  const { warehouses } = useSelector((state) => state.warehouse);
  const { crdentialDataForOne } = useSelector((state) => state.login);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProductReducer());
    dispatch(getCategoryReducerForActive());
    dispatch(getActiveWarehouseReducer());
    // dispatch(
    //   getAllInventoryReducer({ warehouseId: crdentialDataForOne?.warehouse })
    // );
    setTimeout(() => {
      dispatch(
        crdentialDataForOne.role == "admin"
          ? getAllInventoryReducer()
          : getInventorySearchReducer({
              warehouseId: crdentialDataForOne.warehouse,
            })
      );
    }, 200);
    setDisabledCondition(crdentialDataForOne?.role == "warehouseAdmin");
    setWarhousename(crdentialDataForOne?.warehouse);
    setQuantityData([]);
  }, []);

  const handleInventoryQuery = (event) => {
    dispatch(
      getInventorySearchReducer({
        warehouseId:
          crdentialDataForOne?.role == "admin"
            ? warehouseName
            : crdentialDataForOne?.warehouse,
        productQuery: event.target.value,
        categoryId: category,
      })
    );
  };
  console.log("Outgoing Inventroy", outGoingInventory);
  const addOutgoingInventory = () => {
    const arr = Object.entries(quantityData).map(([key, value]) => ({
      name: key,
      value,
    }));
    dispatch(
      createOutgoingInventoryReducer({
        id: productId,
        customer_name: customerName,
        customer_phone: customerPhoneNo,
        transaction_date: transactionDate,
        gin: gin,
        quantity: arr,
      })
    );
    setOpenModal(false);
  };

  const { productsByCategory, products } = useSelector(
    (state) => state.product
  );

  const DisplayModalOutgoing = (record) => {
    setProductQuantity(record?.quantity);
    setProductId(record?._id);
    setOpenModal(true);
  };

  // const handlePhone = (event) => {
  //   const isValid = validatePhoneNumber(event.target.value);
  //   setIsValidPhoneNo(isValid ? "" : "Phone No. must be exactly nine digts");
  //   if (event.target.value.length > 9) {
  //   } else {
  //     setPhone(event.target.value);
  //   }
  // };

  // const validatePhoneNumber = (value) => {
  //   const phoneNumberRegex = /^\d{9}$/;
  //   return phoneNumberRegex.test(value);
  // };
  const handleCustomerNameChange = (event) => {
    setCustomerName(event.target.value);
  };
  const handleCustomerPhoneNo = (event) => {
    setCustomerPhone(event.target.value);
  };

  const handleTransactionDate = (event) => {
    setTransactionDate(event.target.value);
  };

  const onCategoryChange = (event) => {
    // dispatch(getAllproductByCategory(event));
    setCategory(event);
    const filteredData = products?.data?.filter(
      (item) => item.category === event
    );
    setFields(filteredData);
  };

  const onProductCange = (value) => {
    setQuantityMeasures([]);
    const selectedProduct = products.data.find((item) => item._id == value);
    setQuantityMeasures(selectedProduct.quantity);
    // setProductName(value);
  };
  const handleGin = (event) => {
    setGin(event.target.value);
  };

  const handleQuantityData = (evnt) => {
    evnt.persist();
    setQuantityData({
      ...quantityData,
      [evnt.target.name]: evnt.target.value,
    });
  };

  const onWarehouseChange = (evt) => {
    setWarhousename(evt);
  };

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  const minDate = currentDate.toISOString().split("T")[0];

  const onReset = () => {
    form.resetFields();
  };
  const columnsA = [
    {
      title: "Item Code",
      width: "15%",
      render: (text, record) => <span>{record?.product?.code}</span>,
    },
    {
      title: "Product Name",
      width: "content-max",
      render: (text, record) => <span>{record?.product?.name}</span>,
    },
    {
      title: "Product Specfications",
      render: (text, record) => (
        <span>
          {record.product?.specifications?.map((item) => (
            <li>
              {item.name} : <span> {item.value}</span>
            </li>
          ))}
        </span>
      ),
    },

    // {
    //   title: "Category",
    //   width: "10%",
    //   render: (text, record) => <span>{record?.productCategory?.name}</span>,
    // },
    {
      title: "Warehouse",

      render: (text, record) => <span>{record?.warehouse?.name}</span>,
    },
    {
      title: "Quantity",
      width: "25%",
      render: (text, record) => (
        <span>
          {record.quantity.map((item) => (
            <li>
              {item.name}: <b>{item.value}</b>
            </li>
          ))}
        </span>
      ),
    },
    {
      title: "Actions",
      width: "15%",
      render: (text, record) => (
        <span>
          {/* <Button
            onClick={() => displayModal(record)}
            className="mr-2"
            icon={<PlusCircleOutlined />}
          ></Button> */}
          <Button
            onClick={() => DisplayModalOutgoing(record)}
            className="mr-2"
            icon={<MinusCircleOutlined />}
          ></Button>
        </span>
      ),
    },
  ];

  return (
    <div className="ml-10">
      <Modal
        centered
        onOk={() => addOutgoingInventory()}
        onCancel={() => setOpenModal(false)}
        title={"Outgoing Inventory"}
        visible={openModal}
        style={{ minWidth: "650px" }}
      >
        <Row gutter={30}>
          <Col xs={24} sm={24} lg={12}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
            >
              <Form.Item
                label="Customer Name"
                name="customerName"
                rules={[
                  {
                    required: true,
                    message: "Customer Name is required",
                  },
                ]}
                style={{ marginTop: "-20px" }}
              >
                <Input
                  name="customerName"
                  value={customerName}
                  onChange={handleCustomerNameChange}
                ></Input>
              </Form.Item>
              <Form.Item
                label="Customer's Phone No"
                name="customerPhoneNo"
                rules={[
                  {
                    required: true,
                    message: "Customers Phone No is required",
                  },
                  {
                    type: Number,
                  },
                ]}
                style={{ marginTop: "-20px" }}
              >
                <Input
                  value={customerPhoneNo}
                  onChange={handleCustomerPhoneNo}
                ></Input>
              </Form.Item>
              <Form.Item
                label="Transaction Date"
                style={{ marginTop: "-20px" }}
              >
                <Input
                  type="date"
                  value={transactionDate}
                  max={minDate}
                  onChange={handleTransactionDate}
                ></Input>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24} lg={12}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
            >
              <Form.Item
                name="gin"
                label="GIN"
                rules={[
                  {
                    required: true,
                    message: "GIN is required",
                  },
                ]}
                style={{ marginTop: "-20px" }}
              >
                <Input name="gin" value={gin} onChange={handleGin}></Input>
              </Form.Item>
              {productQuantity?.map((item) => (
                <Form.Item
                  className="capitalize"
                  name={item.name}
                  label={item.name}
                  style={{ marginTop: "-20px" }}
                >
                  <Input
                    name={item.name}
                    value={item.value}
                    onChange={handleQuantityData}
                  />
                </Form.Item>
              ))}
            </Form>
          </Col>
        </Row>
      </Modal>
      <div>
        <p
          className="text-xl mb-4"
          style={{
            color: "#1E90FF",
          }}
        >
          <b>Outgoing Inventory</b>
        </p>
        <Row gutter={10}>
          <Col xs={24} sm={24} lg={8}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
              style={{
                maxWidth: 400,
              }}
            >
              <Form.Item
                label="Warehouse"
                rules={[
                  {
                    required: true,
                    message: "Warehouse is Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Warehouse"
                  allowClear
                  // disabled
                  name="warehouseName"
                  disabled={disabledCondition}
                  value={warehouseName}
                  onChange={onWarehouseChange}
                >
                  {warehouses?.map((item, key) => (
                    <Option key={key} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24} sm={24} lg={8}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
              style={{
                maxWidth: 400,
              }}
            >
              <Form.Item
                layout="vertical"
                name="productCategory"
                label="Product Category"
                rules={[
                  {
                    required: true,
                    message: "Product Category is Required",
                  },
                ]}
              >
                <Select
                  name="category"
                  value={category}
                  onChange={onCategoryChange} //
                  placeholder="Select product category"
                  allowClear
                >
                  {categories?.map((item, key) => (
                    <Option key={key} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={24} sm={24} lg={8}>
            <Form layout="vertical" form={form} name="control-hooks">
              <Form.Item name="productname" label="Product Name">
                <Input
                  style={{ height: "30px !important" }}
                  value={productQuery}
                  name="productQuery"
                  onChange={handleInventoryQuery} //handleInventoryQuery
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row></Row>
        <Row gutter={16}>
          {/* <Row gutter={5}> */}
          <Form
            layout="vertical"
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            style={{
              maxWidth: 600,
            }}
          ></Form>
        </Row>
        <div>
          <TableA
            columnnn={columnsA}
            dataaa={outGoingInventory}
            isLoadinggg={isLoading}
          />
        </div>
      </div>
    </div>
  );
};
export default OutgoingInventory;
