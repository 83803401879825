import React, { useEffect, useState } from "react";
import XLSX from "xlsx";

import {
  Button,
  Row,
  Upload,
  message,
  Col,
  Form,
  Input,
  Select,
  Modal,
} from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  createInventoryreducer,
  createOutgoingInventoryReducer,
  getInventorySearchReducer,
  getInventoryReducer,
  getProductsSearchReducer,
} from "../../store/inventoryReducer";
import {
  UploadOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  categories,
  getCategoryReducerForActive,
} from "../../store/categoryReducers";
import { getActiveWarehouseReducer } from "../../store/warehouseReducer";
import {
  getAllproductByCategory,
  getProductReducer,
} from "../../store/productReducer";
import TableA from "../../components/tables/table";
import Item from "antd/es/list/Item";
import { toast } from "react-toastify";
const { Option } = Select;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const Incominginventory = () => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    //
  };
  const allowedFileTypes = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const beforeUpload = (file) => {
    const fileType = file.type;
    const isExcelFile = allowedFileTypes.includes(fileType);

    if (!isExcelFile) {
      message.error("Only Excel files are allowed!");
    }

    return isExcelFile;
  };
  const [fields, setFields] = useState([]);
  const [quantityMeasures, setQuantityMeasures] = useState([]);
  const [quantityData, setQuantityData] = useState([]);
  const [disabledConditions, setDisabledConditions] = useState(false);
  const [productQuantity, setProductQuantity] = useState([]);
  const [productId, setProductId] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  const [openModalForHelp, setOpenModalForHelp] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [category, setCategory] = useState("");
  const [productQuery, setProductQuery] = useState("");
  const [warehouseName, setWarhousename] = useState("");
  const [grn, setGrn] = useState("");
  const [categoryId, setCategoryId] = useState("");

  const { inventories } = useSelector((state) => state.inventory);
  const [inventorystatus, setInventoryStatus] = useState(""); // to know wheather it is incoming or outgoing
  const [descriptionn, setDescrpitionn] = useState("");

  const [handleModal, setHandleModal] = useState("");
  //
  const { categories } = useSelector((state) => state.category);
  const { warehouses } = useSelector((state) => state.warehouse);
  const { crdentialDataForOne } = useSelector((state) => state.login);
  const [disabledCondition, setDisabledCondition] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProductReducer());
    dispatch(getCategoryReducerForActive());
    dispatch(getActiveWarehouseReducer());
    setQuantityData([]);
    setDisabledCondition(crdentialDataForOne?.role == "warehouseAdmin");
    setWarhousename(crdentialDataForOne?.warehouse);
  }, []);
  // setWarhousename(crdentialDataForOne?.warehouse);
  const handleProductQuery = (event) => {
    dispatch(
      getProductsSearchReducer({
        query: event.target.value,
      })
    );
  };

  const addIncomingInventory = () => {
    const arr = Object.entries(quantityData).map(([key, value]) => ({
      name: key,
      value,
    }));
    dispatch(
      createInventoryreducer({
        warehouse:
          crdentialDataForOne?.role == "admin"
            ? warehouseName
            : crdentialDataForOne?.warehouse,
        product: productId,
        quantity: arr,
        grn: grn,
      })
    );
    setOpenModal(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event?.fileList[0]?.originFileObj);
  };

  const handleUpload = () => {
    const token = localStorage.getItem("token");
    const formData = new FormData();

    formData.append("file", selectedFile);
    formData.append("category", category);
    formData.append("warehouse", warehouseName);
    formData.append("grn", grn);
    axios
      .post(
        "https://erp.marnamanufacturing.com/api/inventory/incoming/import",
        // "http://localhost:5000/api/inventory/incoming/import",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Excel imported Successfully.");
      })
      .catch((error) => {
        console.error("EORRRR", error);
        // Handle any errors
      });
    setOpenModal2(false);
  };

  const { productsByCategory, products } = useSelector(
    (state) => state.product
  );

  const displayModal = (record, handleModal) => {
    setHandleModal(handleModal);
    if (handleModal === "IncomingInventory") {
      if (warehouseName == "") {
        toast("Please select warehouse");
      } else {
        setProductQuantity(record?.quantity);
        setProductId(record?._id);
        setOpenModal(true);
        setInventoryStatus("incoming");
      }
    } else {
      setOpenModal(true);
    }
  };

  const onCategoryChange = (event) => {
    setCategory(event);
    const filteredData = products?.data?.filter(
      (item) => item.category === event
    );
    setFields(filteredData);
  };
  const onCategoryChangeForExcelDownload = (event) => {
    setCategoryId(event);
  };
  const handlGRN = (event) => {
    setGrn(event.target.value);
  };

  const onProductCange = (value) => {
    setQuantityMeasures([]);
    const selectedProduct = products.data.find((item) => item._id == value);
    setQuantityMeasures(selectedProduct.quantity);
    // setProductName(value);
  };

  const handleQuantityData = (evnt) => {
    evnt.persist();
    setQuantityData({
      ...quantityData,
      [evnt.target.name]: evnt.target.value,
    });
  };

  const handleGrn = (event) => {
    setGrn(event.target.value);
  };

  const onWarehouseChange = (evt) => {
    setWarhousename(evt);
  };

  const onReset = () => {
    form.resetFields();
  };

  const handleExcelDownload = () => {
    const url = `https://erp.marnamanufacturing.com/api/inventory/download/template/${categoryId}`;
    // const url = `http://127.0.0.1:5000/api/inventory/download/template/${categoryId}`;

    window.open(url, "_blank");
  };
  //
  const showModal2 = () => {
    setOpenModal2(true);
  };

  //
  const columnsA = [
    {
      title: "Item Code",
      width: "19%",
      render: (text, record) => (
        <span>
          {record.code}
          <br />
        </span>
      ),
      ellipsis: true,
    },
    {
      title: "Product Name",
      width: "19%",
      render: (text, record) => <span>{record.name}</span>,
    },

    {
      title: "Specfications",

      render: (text, record) => (
        <span>
          {record.specifications?.map((item) => (
            <li>
              {item.name} : <span> {item.value}</span>
            </li>
          ))}
        </span>
      ),
    },
    {
      title: "Quantity",
      width: "15%",
      render: (text, record) => (
        <ul>
          {record?.quantity?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      ),
    },
    {
      title: "Actions",
      width: "8%",
      render: (text, record) => (
        <span>
          <Button
            onClick={() => displayModal(record, "IncomingInventory")}
            className="mr-2"
            icon={<PlusCircleOutlined />}
          ></Button>
        </span>
      ),
    },
  ];

  return (
    <div className="ml-4">
      <Modal
        centered
        onOk={
          inventorystatus == "incoming"
            ? addIncomingInventory
            : handleExcelDownload
        }
        onCancel={() => setOpenModal(false)}
        title={
          inventorystatus == "incoming"
            ? "Incomming Inventory"
            : "Download Excel Template"
        }
        visible={openModal}
      >
        {handleModal === "IncomingInventory" ? (
          <Row gutter={5}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
              style={{
                width: 300,
              }}
            >
              <Form.Item
                label="Warehouse"
                rules={[
                  {
                    required: true,
                    message: "Warehouse required",
                  },
                ]}
                style={{ marginTop: "-20px" }}
              >
                <Select
                  placeholder="Select Warehouse"
                  allowClear
                  name="warehouseName"
                  disabled={disabledCondition}
                  value={warehouseName}
                  onChange={onWarehouseChange}
                >
                  {warehouses.map((item, key) => (
                    <option key={key} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="grn"
                label="GRN"
                rules={[
                  {
                    required: true,
                    message: "GRN required",
                  },
                ]}
                style={{ marginBottom: "2px", marginTop: "-20px" }}
              >
                <Input name="grn" value={grn} onChange={handleGrn}></Input>
              </Form.Item>
            </Form>
            {productQuantity?.map((item) => (
              <Col xs={24} lg={24}>
                <Form
                  layout="vertical"
                  form={form}
                  name="control-hooks"
                  onFinish={onFinish}
                  style={{
                    width: 300,
                  }}
                >
                  <Form.Item
                    className="capitalize"
                    name={item}
                    label={item}
                    style={{ marginBlock: "2px" }}
                  >
                    <Input
                      name={item}
                      value={item}
                      onChange={handleQuantityData}
                    />
                  </Form.Item>
                </Form>
              </Col>
            ))}
          </Row>
        ) : (
          <div>
            <Form>
              <Form.Item label="Product Category">
                <Select
                  placeholder="Select Category"
                  name="category"
                  value={categoryId}
                  onChange={onCategoryChangeForExcelDownload}
                >
                  {categories?.map((item, key) => (
                    <option key={key} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </div>
        )}
      </Modal>

      <Modal
        centered
        onOk={handleUpload}
        onCancel={() => setOpenModal2(false)}
        title="Incoming Inventory Import from Excel"
        visible={openModal2}
      >
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={onFinish}
        >
          <Form.Item
            label="Warehouse"
            rules={[
              {
                required: true,
                message: "Warehouse is required",
              },
            ]}
          >
            <Select
              placeholder="Select Warehouse"
              allowClear
              name="warehouseName"
              disabled={disabledCondition}
              value={warehouseName}
              onChange={onWarehouseChange}
            >
              {warehouses?.map((item, key) => (
                <Option key={key} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={onFinish}
        >
          <Form.Item
            name="productCategory"
            label="Product Category"
            rules={[
              {
                required: true,
                message: "Product Category is Required",
              },
            ]}
          >
            <Select
              name="category"
              value={category}
              onChange={onCategoryChange} //
              placeholder="Select product category"
              allowClear
            >
              {categories?.map((item, key) => (
                <Option key={key} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          onFinish={onFinish}
        >
          <Form.Item name="grn" label="GRN">
            <Input value={grn} name="grn" onChange={handlGRN} />
          </Form.Item>
        </Form>
        <Form.Item style={{ paddingTop: "0px" }}>
          <Upload onChange={handleFileChange} block>
            <Button
              icon={<UploadOutlined />}
              className="custom-upload-button w-full"
              style={{ width: 332, background: "#1E90FF", color: "white" }}
            >
              Upload Inventory From Excel
            </Button>
          </Upload>
        </Form.Item>
      </Modal>

      <div>
        <p className="text-xl mb-2" style={{ color: "#1E90FF" }}>
          <b>Incoming Inventory</b>
        </p>

        <Row gutter={[20, 50]}>
          <Col xs={24} sm={24} lg={8}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
            >
              <Form.Item name="productname" label="Product Name">
                <Input
                  value={productQuery}
                  name="productQuery"
                  onChange={handleProductQuery}
                  disabled={disabledConditions}
                  placeholder="Search by Product"
                />
              </Form.Item>
            </Form>
          </Col>

          <Col xs={24} sm={24} lg={16}>
            <div style={{ paddingTop: "30px" }}>
              <Button
                style={{
                  backgroundColor: "#4169E1",
                  color: "white",
                }}
                onClick={showModal2}
              >
                Upload Inventory from Excel
              </Button>
              <Button
                className=""
                htmlType="submit"
                style={{
                  backgroundColor: "#4169E1",
                  color: "white",
                }}
                onClick={() => displayModal("Help")}
              >
                Download Template
              </Button>
            </div>
          </Col>
        </Row>
        <div>
          <TableA columnnn={columnsA} dataaa={inventories} />
        </div>
      </div>
    </div>
  );
};
export default Incominginventory;
